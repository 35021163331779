import GoogleMapReact from "google-map-react"
import React from "react"
import { Marker } from "react-map-gl"
import { usePrinticularMapTemporaryCredentials } from "~/components/store-search/aws/hooks/usePrinticularMapTemporaryCredentials"
import MapAWS from "~/components/store-search/aws/MapAWS"
import StoreMarker from "~/components/store-search/StoreMarker"
import { useSettings } from "~/hooks/useSettings"
import { RemoteStoreApi } from "./Stores"

interface Props {
  store: RemoteStoreApi
}

const StoreMap: React.FC<Props> = ({ store }) => {
  const { settings } = useSettings()
  const { credentials } = usePrinticularMapTemporaryCredentials()

  switch (settings.mapProvider) {
    case "googleMaps":
      return (
        <GoogleMapReact
          center={{
            lat: +store.latitude,
            lng: +store.longitude,
          }}
          defaultZoom={15}
        >
          <StoreMarker
            id={`store-${store.id}-marker`}
            lat={+store.latitude}
            lng={+store.longitude}
            label={store.name}
          />
        </GoogleMapReact>
      )
    case "amazonLocation":
    default:
      if (credentials) {
        return (
          <MapAWS
            credentials={credentials}
            latitude={+store.latitude}
            longitude={+store.longitude}
          >
            <Marker longitude={+store.longitude} latitude={+store.latitude}>
              <StoreMarker
                id={`store-${store.id}-marker`}
                lat={+store.latitude}
                lng={+store.longitude}
                label={store.name}
              />
            </Marker>
          </MapAWS>
        )
      }
      return null
  }
}

export default StoreMap
